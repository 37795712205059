<template>
<!-- 寄售弹框 -->
  <div class="tableList">
    <div class="code">对账单编号:</div>
     <global-table ref="addDetailtable"  :tableField="tableField" :tableData="tableData"></global-table>
  </div>
</template>

<script>
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { tableField } from './supStatementDetailDialogY'
export default {
  name: 'SupStatementDetailDialogY',
  components: { GlobalTable },
  props: ['formData'],
  data () {
    return {
      tableField: tableField,
      tableData: this.formData.reconciliations
    }
  },
  created () {

  },
  methods: {

  }
  // pageChange (data) {
  //   this.paginationData.pageSize = data
  //   this.StatementDetaildialogList()
  // }
}
</script>
<style scoped>
.tableList{
padding: 0px;
}
.code{
  margin-bottom: 20px;
}
</style>
